import { render, staticRenderFns } from "./WalletReport.vue?vue&type=template&id=5c4c5794&scoped=true"
import script from "./WalletReport.vue?vue&type=script&lang=js"
export * from "./WalletReport.vue?vue&type=script&lang=js"
import style0 from "./WalletReport.vue?vue&type=style&index=0&id=5c4c5794&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c4c5794",
  null
  
)

export default component.exports