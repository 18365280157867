<template>
  <div class="wallet">
    <PageTitle title="儲值金報表" btn="匯出" @btnClick="showExportOptions = true" />

    <section class="wallet-report__history">
      <SectionTitle title="歷史統計" fontSize="18" hideBtn />
      <div class="history__content">
        <div
          v-for="(item, key, index) in walletHistoryStatistics"
          :key="key"
          class="content__item"
        >
          <div class="item__label font-[12px]">
            <span class="mx-1">{{ item.label }}</span>
            <span v-if="item.tip">{{ item.tip }}</span>
          </div>
          <div class="item__value">
            <span v-show="index === 2 && item.value && item.value < 0">- </span>
            $ {{ Math.abs(item.value) }}
          </div>
        </div>
      </div>
    </section>

    <section class="wallet-report__record">
      <SectionTitle title="區間內統計" fontSize="18" hideBtn />
      <div class="record__datetime">
        <GroupTimeFilterButton
          :isCustomRange.sync="customerRange"
          @month="getRangeThisMonth"
          @week="getRangeThisWeek"
          @today="getRangeToday"
        />

        <el-date-picker
          v-model="search.range"
          editable
          class="datetime__picker"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始時間"
          end-placeholder="結束時間"
          format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
          @change="
            getWalletRecord();
            datetime.activeType = 'customized';
            customerRange = true;
          "
        />
      </div>

      <SectionTitle title="總覽" fontSize="18" hideBtn hideTitleStick class="mt-[20px]" />
      <div class="record__content">
        <div
          v-for="(item, key, index) in walletRecordStatistics"
          :key="key"
          class="content__item"
        >
          <div class="item__label">{{ item.label }}</div>
          <div class="item__value">
            <span v-if="index === 2 && item.value && item.value < 0">-</span>
            <span v-if="index !== 0">$ </span>
            {{ Math.abs(item.value) }}
          </div>
        </div>
      </div>

      <div v-if="false">
        <div class="section__title">
          <SectionTitle title="各操作單位統計" fontSize="18" hideBtn hideTitleStick />
        </div>

        <BaseTable
          v-loading="loading"
          class="record__table"
          empty-text="暫無數據"
          :data="storeList"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <EmptyBlock slot="empty" />
          <BaseElTableColumn label="門市" prop="name" sortable align="center">
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </BaseElTableColumn>
          <BaseElTableColumn label="實際儲值金額" props="depositTotal" sortable>
            <template slot-scope="scope">
              {{ indicatorByBranchList[scope.row.id]?.depositTotal || 0 }}
            </template>
          </BaseElTableColumn>
          <BaseElTableColumn label="實際使用金額" props="useTotal" sortable>
            <template slot-scope="scope">
              {{ indicatorByBranchList[scope.row.id]?.useTotal || 0 }}
            </template>
          </BaseElTableColumn>
        </BaseTable>
        <Pagination
          :pageLimit="10"
          :total="branchCount"
        />
      </div>

      <div class="section__title">
        <SectionTitle title="儲值金紀錄列表" fontSize="18" hideBtn />
      </div>
      <FiltersContainer style="margin-top: 30px" flat>
        <BaseElInput
          v-model="search.phone"
          class="w-full"
          clearable
          suffix-icon="el-icon-search"
          placeholder="請輸入電話號碼"
          @change="getWalletRecord()"
        />
        <BaseElSelect
          v-model="search.noteCode"
          class="w-full"
          clearable
          placeholder="請選擇形式"
          @change="getWalletRecord()"
        >
          <BaseElSelectOption
            v-for="item in noteCodeSearchOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
        <BaseElInput
          v-model="search.creatorName"
          clearable
          suffix-icon="el-icon-search"
          placeholder="請輸入操作人員"
          @change="getWalletRecord()"
        />
      </FiltersContainer>

      <BaseTable
        v-loading="loading"
        class="record__table"
        empty-text="暫無數據"
        :data="walletRecordList"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <EmptyBlock slot="empty" />

        <BaseElTableColumn
          prop="userInfo.name"
          label="姓名"
        />
        <BaseElTableColumn
          prop="userInfo.phone"
          label="電話號碼"
        />
        <BaseElTableColumn
          prop="noteCode"
          label="形式"
        >
          <template slot-scope="scope">
            {{ convertNoteCode(scope.row.noteCode) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="amount"
          label="儲值金"
        />
        <BaseElTableColumn
          prop="creatorName"
          label="操作人員"
        />
        <BaseElTableColumn v-if="false" prop="Branch" label="門市">
          <template slot-scope="scope">
            {{ scope.row.Branch?.name || "-" }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="createdAt"
          label="建立時間"
        >
          <template slot-scope="scope">
            {{ dateTime(scope.row.createdAt) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="note"
          label="備註"
        />
      </BaseTable>

      <Pagination
        :curPage.sync="pagination.page"
        :pageLimit="pagination.pageSize"
        :total="walletRecordListCount"
        @pageChange="refresh"
      />
    </section>

    <ExportOptionsDialog
      v-if="showExportOptions"
      allRange
      :useExportTask="useExportTask"
      @close="showExportOptions = false"
      @export="showExportOptions = false, prepareExport()"
    />
    <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="exportTotal"
      @close="resetExport"
    />
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import ExportMixin from '@/mixin/export'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import GroupTimeFilterButton from '@/components/Input/GroupTimeFilterButton'
import { mapGetters } from 'vuex'
import {
  GetWalletRecord,
  GetWalletRecordCount,
  GetWalletRecordIndicators,
  FindIndicatorsByBranch,
} from '@/api/wallet'
import { CreateWalletRecordSheetExportTask } from '@/api/exportTask'
import { FindStoreCount, FindStore } from '@/api/store'
import {
  noteCodeOptions,
  noteCodeSearchOptions,
  exportFormatOptions,
} from '@/config/wallet'
import EmptyBlock from '@/components/EmptyBlock.vue'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import {
  dateTimeFormat,
  getAllDataFromApi,
} from '@/utils/helper'
import { checkUserFeature } from '@/store/modules/permission'
import dayjs from '@/lib/dayjs'
import { usePermissions } from '@/use/permissions'
import { useExportCenter } from '@/use/useExportCenter'

export default defineComponent({
  name: 'WalletReport',
  components: {
    EmptyBlock,
    GroupTimeFilterButton,
    ExportOptionsDialog,
    ExportDialog,
  },
  mixins: [ExportMixin],
  setup () {
    const { checkAction } = usePermissions()
    const useExportTask = computed(() => checkAction('admin.walletRecord.createSheetExportTask'))
    const { messageOptions } = useExportCenter()

    return {
      useExportTask,
      messageOptions,
    }
  },
  data () {
    return {
      customerRange: false,
      noteCodeOptions,
      noteCodeSearchOptions,
      exportFormatOptions,
      loading: false,
      walletHistoryStatistics: {
        depositTotal: {
          label: '實際儲值金額',
          value: 0,
        },
        useTotal: {
          label: '實際使用金額',
          value: 0,
        },
        unrealized: {
          label: '未實現金額',
          tip: '(實際儲值金額-實際使用金額)',
          value: 0,
        },
      },
      walletRecordStatistics: {
        count: {
          label: '操作筆數',
          value: 0,
        },
        deposit: {
          label: '實際儲值金額',
          value: 0,
        },
        use: {
          label: '實際使用金額',
          value: 0,
        },
      },
      datetime: {
        buttonOptions: {
          today: {
            label: '本日',
          },
          thisWeek: {
            label: '本週',
          },
          thisMonth: {
            label: '本月',
          },
          customized: {
            label: '自定義',
          },
        },
        activeType: 'today',
      },
      search: {
        range: null,
        phone: '',
        noteCode: '',
        creatorName: '',
      },
      walletRecordListCount: 0,
      walletRecordList: [],
      pagination: {
        page: 1,
        pageSize: 10,
      },
      exportFormData: {
        format: 'xlsx',
      },
      exportExcelColumns: [
        {
          label: '姓名',
          field: 'userInfo.name',
        },
        {
          label: '電話號碼',
          field: 'userInfo.phone',
        },
        {
          label: '形式',
          field: 'noteCode',
          dataFormat: this.convertNoteCode,
        },
        {
          label: '儲值金',
          field: 'amount',
        },
        {
          label: '操作人員',
          field: 'creatorName',
        },
        {
          label: '建立時間',
          field: 'createdAt',
          dataFormat: this.dateTime,
        },
        {
          label: '備註',
          field: 'note',
        },
      ],
      indicatorByBranchList: {},
      storeList: [],
      branchCount: 0,
      storeStart: 0,
      storeLimit: 15,
    }
  },

  computed: {
    ...mapGetters([
      'shop',
      'userPlanFeature',
      'userFeatures',
    ]),

    useWalletRecordExportExcel () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.walletRecord.exportExcel')
    },
    useStorePermission () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.branch.adminUseStore')
    },

    getExportFileName () {
      const start = this.search.range
        ? this.dateTime(this.search.range[0]).replace(' ', '-').replaceAll('/', '').replaceAll(':', '')
        : ''
      const end = this.search.range
        ? this.dateTime(this.search.range[1]).replace(' ', '-').replaceAll('/', '').replaceAll(':', '')
        : ''

      return `儲值金報表${this.search.range ? `_${start}_${end}` : ''}`
    },
  },

  watch: {
    async 'datetime.activeType' (current) {
      if (current !== 'customized') await this.getWalletRecord()
    },
  },

  async mounted () {
    this.loading = true
    await this.getRangeToday()
    // await this.getWalletTotalIndicators()
    // await this.getWalletRecord()
    // if (this.useStorePermission) {
    //   await Promise.allSettled([
    //     this.findStoreCount(),
    //     this.getStore(),
    //   ])
    //   await this.findIndicatorByBranch()
    // }

    this.loading = false
  },

  methods: {
    async refresh (page) {
      await this.getWalletTotalIndicators()
      await this.getWalletRecord(page)
      if (this.useStorePermission) {
        await Promise.allSettled([
          this.findStoreCount(),
          this.getStore(),
        ])
        await this.findIndicatorByBranch()
      }
    },
    async getWalletTotalIndicators () {
      const res = await GetWalletRecordIndicators({ shopId: this.shop })

      for (const key in this.walletHistoryStatistics) {
        this.walletHistoryStatistics[key].value = res[key]
      }
    },

    async getWalletRecordIndicators () {
      const res = await GetWalletRecordIndicators({
        shopId: this.shop,
        createdAtStart: this.search ? this.search.range[0] : undefined,
        createdAtEnd: this.search ? this.search.range[1] : undefined,
      })

      this.walletRecordStatistics.count.value = res.count
      this.walletRecordStatistics.deposit.value = res.depositTotal
      this.walletRecordStatistics.use.value = res.useTotal
    },

    async getWalletRecordListCount () {
      const res = await GetWalletRecordCount({
        shopId: this.shop,
        phone: this.search.phone ? this.search.phone : undefined,
        noteCodeType: this.search.noteCode ? this.search.noteCode : undefined,
        creatorName: this.search.creatorName ? this.search.creatorName : undefined,
        createdAtStart: this.search ? this.search.range[0] : undefined,
        createdAtEnd: this.search ? this.search.range[1] : undefined,
      })

      this.walletRecordListCount = res.count
      this.exportTotal = res.count
    },

    async getWalletRecordList () {
      const res = await GetWalletRecord({
        shopId: this.shop,
        start: (this.pagination.page - 1) * this.pagination.pageSize,
        limit: this.pagination.pageSize,
        phone: this.search.phone ? this.search.phone : undefined,
        noteCodeType: this.search.noteCode ? this.search.noteCode : undefined,
        creatorName: this.search.creatorName ? this.search.creatorName : undefined,
        createdAtStart: this.search ? this.search && this.search.range[0] : undefined,
        createdAtEnd: this.search ? this.search && this.search.range[1] : undefined,
      })

      this.walletRecordList = res
    },

    setSearchRange (start, end) {
      this.search.range = [start, end]
    },

    clearSearchRange () {
      this.search.range = null
    },

    async getRangeToday () {
      console.log('getRangeToday')
      const start = startOfDay(new Date()).toISOString()
      const end = endOfDay(new Date()).toISOString()

      this.setSearchRange(start, end)
      this.customerRange = false
      await this.refresh(this.pagination.page)
      // await this.findIndicatorByBranch()
    },

    async getRangeThisWeek () {
      console.log('getRangeThisWeek')
      const start = startOfWeek(new Date()).toISOString()
      const end = endOfWeek(new Date()).toISOString()

      this.setSearchRange(start, end)
      this.customerRange = false
      await this.refresh(this.pagination.page)
      // await this.findIndicatorByBranch()
    },

    async getRangeThisMonth () {
      console.log('getRangeThisMonth')
      const start = startOfMonth(new Date()).toISOString()
      const end = endOfMonth(new Date()).toISOString()

      this.setSearchRange(start, end)
      this.customerRange = false
      await this.refresh(this.pagination.page)
      // await this.findIndicatorByBranch()
    },

    convertNoteCode (noteCode) {
      return noteCodeOptions ? noteCodeOptions.find(item => item.value === noteCode).label : '-'
    },

    toggleActive (key) {
      this.datetime.activeType = key
      if (key === 'today') this.getRangeToday()
      else if (key === 'thisWeek') this.getRangeThisWeek()
      else if (key === 'thisMonth') this.getRangeThisMonth()
      else this.clearSearchRange()
    },

    async getWalletRecord (targetPage = 1) {
      this.loading = true
      if (this.search.range === null) this.clearSearchRange()
      if (this.search && this.search.range && this.search.range[0]) {
        this.setPaginationPage(targetPage)
        await this.getWalletRecordIndicators()
        await this.getWalletRecordListCount()
        await this.getWalletRecordList()
      }
      this.loading = false
    },

    dateTime (dateTime) {
      return dateTimeFormat(dateTime).dateTime.replaceAll('-', '/')
    },

    setPaginationPage (page) {
      this.pagination.page = page
    },

    async getExportData () {
      const payload = {
        shopId: this.shop,
        start: 0,
        limit: 100,
        phone: this.search.phone ? this.search.phone : undefined,
        noteCodeType: this.search.noteCode ? this.search.noteCode : undefined,
        creatorName: this.search.creatorName ? this.search.creatorName : undefined,
        createdAtStart: this.search ? this.search && this.search.range[0] : undefined,
        createdAtEnd: this.search ? this.search && this.search.range[1] : undefined,
      }

      const res = await getAllDataFromApi(
        this.exportTotal,
        GetWalletRecord,
        payload,
      )
      this.exportData = res
    },

    async prepareExport () {
      if (this.useExportTask) {
        const [, err] = await CreateWalletRecordSheetExportTask({
          shopId: this.shop,
          phone: this.search.phone ? this.search.phone : undefined,
          noteCodeType: this.search.noteCode ? this.search.noteCode : undefined,
          creatorName: this.search.creatorName ? this.search.creatorName : undefined,
          createdAtStart: this.search ? this.search && this.search.range[0] : undefined,
          createdAtEnd: this.search ? this.search && this.search.range[1] : undefined,
        })
        if (err) {
          this.$message.error(err)
          return
        }
        this.$message(this.messageOptions)
      } else {
        if (!this.useWalletRecordExportExcel) return this.$message.error('無此權限 admin.walletRecord.exportExcel')
        this.resetExport()
        await this.getWalletRecordListCount()
        if (!this.exportTotal) {
          this.$message.warning('沒有資料可以匯出')
          return
        }
        this.showExportDialog = true
        this.exportting = true
        await this.getExportData()
        await this.formatExportData()
        this.exportting = false
      }
    },

    async formatExportData () {
      const data = []
      let count = 0
      this.exportData.forEach((item) => {
        const row = {
          姓名: item.userInfo.name,
          電話號碼: item.userInfo.phone,
          形式: this.convertNoteCode(item.noteCode),
          儲值金: item.amount,
          操作人員: item.creatorName,
          建立時間: dayjs(item.createdAt).format('YYYY/MM/DD'),
          備註: item.note,
        }
        data.push(row)
        count++
        this.exportPercentage = (count / this.exportTotal) * 100
      })
      this.ExportExcel(data, '儲值金報表', this.getExportFileName)
    },
    async findIndicatorByBranch () {
      if (this.useStorePermission) {
        this.loading = true
        const [res, err] = await FindIndicatorsByBranch({
          shopId: this.shop,
          createdAtStart: this.search.range[0],
          createdAtEnd: this.search.range[1],
        })
        this.loading = false
        if (err) {
          this.$message.error(err)
        }
        this.indicatorByBranchList = this.translateIndicatorByBranchToObject(res)
        console.log(res)
      }
    },
    async findStoreCount () {
      this.loading = true
      const [res, err] = await FindStoreCount({ shopId: this.shop })
      this.loading = false
      if (err) {
        window.$message.error(err)
        return
      }
      this.branchCount = res.count
    },
    async getStore () {
      this.loading = true
      const [res, err] = await FindStore({
        shopId: this.shop,
        start: this.storeStart,
        limit: this.storeLimit,
      })
      this.loading = false
      if (err) {
        window.$message.error(err)
        return
      }
      console.log('store', res)
      this.storeList = res
    },
    translateIndicatorByBranchToObject (data) {
      const obj = {}
      data.forEach((item) => {
        obj[item.id] = item
      })
      return obj
    },
  },
})
</script>

<style scoped lang="scss">

.wallet {
  color: #333333;
}

.wallet-report__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet-report__history {
  position: relative;
  margin-top: 32px;
}

.wallet-report__record {
  position: relative;
  margin-top: 35px;

  .record__table {
    margin-top: 12px;
  }

  .record__pagination {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }
}

.history__content,
.record__content {
  display: flex;
  padding: 3px;
  min-height: 79px;
  background-color: white;
}

.history__content {
  margin-top: 22px;
}

.record__search {
  display: flex;
  gap: 0 8px;
  margin-top: 20px;
}

.record__datetime {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 22px;

  .datetime__button,
  .datetime__picker {
    flex: 1 1 auto;
  }

  .el-button {
    min-width: 138px;
    color: white;

    &.active {
      color: white;
      background-color: var(--primary-100);
    }
  }
}

.record__content {
  margin-top: 12px;
}

.content__item {
  position: relative;
  flex: 1 1 33%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  &:not(:last-of-type)::after {
    content: "";
    position: absolute;
    top: 10%;
    right: -1px;
    width: 2px;
    height: 80%;
    background-color: #DCDFE6;
  }

  .item__label {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #2d271b;
    font-size: 14px;
    line-height: 21px;
  }

  .item__value {
    margin-top: 4px;
    color: var(--primary-100);
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }
}
.section__title {
  margin-top: 20px;
}
</style>
